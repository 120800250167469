
import { Vue, Component } from 'vue-property-decorator'
import http from '@/api/http'

@Component
export default class MedicineList extends Vue {
  private list: any[] = []
  private created () {
    http.get('/uapi/article/md/getByIds?ids=100014,100015').then((res) => {
      this.list = res.result.list
    })
  }
}
